
$switch-padding: 5px;
$switch-width: 40px + (2 * $switch-padding);
$switch-dot-diameter: 20px;

.CheckBox {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    &__switch {
        padding: $switch-padding;
        width: $switch-width;
        border-radius: $switch-dot-diameter;
        box-shadow: inset 0 0 8px 0 rgba(#000, 0.1);
        background-color: #dfdfdf;
        transition-duration: 160ms;
        transition-property: background-color;

        &--checked {
            background-color: #88c1ff;
        }

        &-dot {
            width: $switch-dot-diameter;
            height: $switch-dot-diameter;
            border-radius: $switch-dot-diameter;
            transition-duration: 160ms;
            transition-property: transform, background-color;
            transition-timing-function: ease-in-out;
            transform-origin: left;
            transform: translateX(0);
            background-color: #a8a8a8;

            :active > & {
                transform: translateX(0) scaleX(1.3);
            }

            &--checked {
                $translateX: translateX($switch-width - $switch-dot-diameter);

                transform-origin: right;
                transform: $translateX;
                background-color: white;

                :active > & {
                    transform: $translateX scaleX(1.3);
                }
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: inherit;
        background-color: #fff;
        border: 2px solid #000;
        color: #000;
        width: 24px;
        height: 24px;
        min-width: 24px;
        // usually ${width - 10px} should be good
        font-size: 14px;

        &--checked {
            background-color: #000;
        }

        &-check {
            opacity: 0;

            :hover > & {
                opacity: 0.3;
            }

            &--checked {
                opacity: 1 !important;
                color: #fff;
            }
        }
    }

    &__label {
        margin-left: 10px;
    }
}
