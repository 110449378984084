
.ItemPicker {
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.1);
    box-shadow: 0 6px 10px rgba(#000, 0.1);
    background-color: #fff;
    overflow: hidden;

    &__items {
        min-width: 280px;
        max-height: 200px;
        overflow-y: auto;
    }

    &__empty,
    &__processing {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__processing {
        height: 40px;
    }

    &__empty {
        text-align: center;
        color: gray;
        height: 35px;
        font-size: 14px;
    }
}
