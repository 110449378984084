
.Avatar {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
