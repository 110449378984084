.Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    user-select: none;
    transition-timing-function: ease-out;
    transition-duration: 300ms;
    transition-property: background-color, color, opacity, border-color;

    &--disabled,
    &--processing {
        pointer-events: none;
    }

    &--disabled {
        opacity: 0.5;
    }

    &--processing {
        opacity: 0.6;
    }

    &__link {
        display: inherit;
        flex-direction: inherit;
        justify-content: inherit;
        align-items: inherit;
        text-align: inherit;
        cursor: inherit;
        user-select: inherit;
        height: 100%;
        width: 100%;

        &:link,
        &:hover,
        &:focus,
        &:visited {
            color: inherit;
        }
    }
}
