.Input {
    display: inline-flex;
    border: 0 none;
    padding: 0 10px;
    width: 100%;
    background-color: #fff;
    transition-property: border-color, color, background-color;
    transition-duration: 180ms;
    transition-timing-function: ease-out;

    &::placeholder {
        color: $placeholder-color;
    }

    &:invalid,
    &:focus {
        box-shadow: none;
        outline: none;
        appearance: none;
    }

    &--isValid,
    &--isInvalid {
        padding-right: 30px;
        background-repeat: no-repeat;
        background-position: center right 6px;
        background-size: 15px;
    }

    &--isValid {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    }

    &--isInvalid {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    }

    &--textarea {
        resize: vertical;
        background-position: top 6px right 6px;
    }
}
